import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const Kontakty = () => {
  return (
    <Layout>
      <Seo title="Kontakty" />
      <h1 className="post-title">Kontakty</h1>
      <h3>Veronika Krejčová</h3>
      <p>
        Telefon: +420 776 630 841<br />
        Email: <a href="mailto: ve.krejcova@centrum.cz">ve.krejcova@centrum.cz</a><br />
        Adresa: Rynárecká 480, 393 01 Pelhřimov
      </p>

      <iframe
        title="Mapa"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.691256412092!2d15.220215916237711!3d49.414744079345795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ce68503d8d3df%3A0x99e6c04b16150c9d!2zUnluw6FyZWNrw6EgNDgwLCAzOTMgMDEgUGVsaMWZaW1vdg!5e0!3m2!1scs!2scz!4v1637260626618!5m2!1scs!2scz"
        width="600" height="450" style={{border:0, maxWidth: "100%"}} allowFullScreen="" loading="lazy" />
    </Layout>
  )
}

export default Kontakty
